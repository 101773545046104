import nd1 from "../assets/images/news/nd1.webp";

const contentTest1 = `<b>Đón xuân con Rồng, QD.TEK và Wasabi sẽ tặng thêm 10% dung lượng lưu trữ Cloud trong suốt thời hạn hợp đồng cho toàn thể quý Khách hàng và Đối tác. Báo giá và Đơn đặt hàng cần được hoàn thành trước ngày 29.02.2024 để nhận ưu đãi!</b> <br /> <br />
ĐIỀU KIỆN & ĐIỀU KHOẢN ÁP DỤNG:<br />
 - Chương trình chỉ áp dụng cho khách hàng lần đầu sử dụng, không áp dụng cho khách hàng gia hạn và các dịch vụ mua thêm hoặc chuyển đổi dung lượng.<br />
- Khuyến mãi chỉ áp dụng trong thời hạn hợp đồng, không áp dụng cho lần gia hạn tiếp theo. Ví dụ, nếu mua 100TB trong 1 năm, bạn sẽ nhận được thêm 10TB miễn phí và tổng dung lượng lưu trữ của bạn sẽ là 110TB với giá của 100TB. Khi gia hạn, bạn sẽ gia hạn với 110TB và sẽ trả phí cho 110TB dung lượng lưu trữ. <br />
- Khuyến mãi tối đa 100TB. <br /> <br />
Với giá chỉ 240 VNĐ/GB, Bảo mật đạt chuẩn chính phủ Mỹ, Hiệu suất cao… Wasabi đang là giải pháp Lưu trữ Cloud S3 “hot” nhất thế giới! Mời trải nghiệm 1TB cho suốt 60 ngày hoàn toàn miễn phí tại:<b><a href="https://wasabi.qdtek.vn/trial-register">https://wasabi.qdtek.vn/trial-register</a></b><br /> <br />
Liên hệ với nhân viên bán hàng QD.TEK của bạn để biết thêm thông tin chi tiết hoặc qua email info@qdtek.vn`;

const contentTest2 = `Ngày 25/01/2024 - Công ty Cổ phần Phân phối Công nghệ Quang Dũng (QD.TEK), đã được Wasabi Technologies (Mỹ), nhà cung cấp dịch vụ lưu trữ đám mây Top 5 thế giới, trao tặng giải thưởng "Đối tác Mới Xuất sắc Năm 2023 khu vực Châu Á Thái Bình Dương" (Rookie Partner of the Year – APAC) tại Lễ trao giải Wasabi Partner Network lần đầu tiên được tổ chức. <br />  <br />
Sự kiện nhằm vinh danh các đối tác nổi trội trên toàn cầu của Wasabi, được đánh giá thông qua sự phát triển vượt bậc, cũng như những giải pháp sáng tạo và hợp tác đặc biệt trong việc cung cấp giải pháp lưu trữ đám mây (cloud storage) cho các doanh nghiệp và khách hàng trong năm 2023.  Giải thưởng "Đối tác Mới Xuất sắc của năm" nhằm ghi nhận sự tăng trưởng vượt bậc của các Đối tác trong năm đầu tiên hợp tác với Wasabi. Wasabi cung cấp dịch vụ lưu trữ đám mây truy xuất nhanh, đáng tin cậy, và giá thành thấp. Dịch vụ lưu trữ của Wasabi có hiệu suất cao, nhưng có chi phí thấp hơn 80% so với các đơn vị cung cấp đám mây dẫn đầu khác, đồng thời lại không tính phí tải dữ liệu hay lệnh gọi API. Sự hợp tác giữa QD.TEK và Wasabi sẽ góp phần đáp ứng nhu cầu ngày càng tăng về lưu trữ đám mây với giá phải chăng tại thị trường Việt Nam. <br />  <br />
<b>Ông Đặng Thạch Quân – Tổng giám đốc QD.TEK cho biết</b>: “QD.TEK rất vinh dự khi được Wasabi chọn lựa trao giải thưởng trong hơn 13.000 đối tác toàn cầu của Wasabi. Chúng tôi sẽ tiếp tục đà phát triển đã được xây dựng trong năm 2023 và sẽ cùng Wasabi cung cấp giải pháp lưu trữ thông minh nhờ trí tuệ nhân tạo cho doanh nghiệp trong năm 2024.” <br /> <br />
Ông Marty Falaro - Phó chủ tịch và Giám đốc điều hành Wasabi Technologies phát biểu: : “Đối tác chính là một phần quan trọng của Wasabi, và chúng tôi rất vinh dự khi QD.TEK đã trở thành một phần không thể thiếu trong mạng lưới đối tác của chúng tôi. Chúng tôi sẽ cùng nhau cung cấp giải pháp lưu trữ đám mây chi phí thấp, đáng tin cậy và có thể mở rộng cho người dùng toàn cầu. Chúng tôi sẽ tiếp tục chương trình này thường niên nhằm ghi nhận sự đóng góp và hợp tác của các đối tác trong việc cung cấp giải pháp lưu trữ đám mây hàng đầu."<br /> <br />
Thông tin chi tiết về Giải thưởng Mạng lưới Đối tác Wasabi 2023 tại: <b><a href="https://wasabi.com/tech-partners/2023-partner-awards/">https://wasabi.com/tech-partners/2023-partner-awards/</a></b> <br /> <br />
<b>Giới thiệu về QD.TEK</b><br /> <br />
Từ 2004, Công ty CP Phân phối Công nghệ Quang Dũng (QD.TEK), thuộc Tập đoàn GREENFEED Việt Nam, là một trong những nhà phân phối CNTT hàng đầu tại Việt Nam, chuyên cung cấp các giải pháp cơ sở hạ tầng trong lĩnh vực viễn thông, an ninh giám sát và công nghệ thông tin. QD.TEK đã trở thành đối tác đáng tin cậy của nhiều nhà sản xuất CNTT hàng đầu trên toàn cầu như Allied Telesis, AXIS, Briefcam, CommScope, Hanwha Vision, Milestone, LS, Thales, và Vertiv, phục vụ một mạng lưới bán hàng vững chắc với hơn 2.000 đối tác trên toàn Việt Nam. QD.TEK tự hào mang đến cho doanh nghiệp những sản phẩm và giải pháp an toàn, chất lượng cao với chi phí tối ưu, cũng như các dự án bền vững về môi trường và trách nhiệm xã hội tại Việt Nam. Để biết thêm thông tin về QD.TEK, truy cập <b><a href="www.qdtek.vn">www.qdtek.vn</a></b><br /> <br />
<b>Về Wasabi Technologies</b><br /> <br />
Wasabi cung cấp dịch vụ lưu trữ đám mây hiệu suất cao với giá hợp lý cho các doanh nghiệp trên toàn thế giới. Chúng tôi giúp các tổ chức lưu trữ và truy xuất nhanh chóng lượng dữ liệu không giới hạn mà không có phân cấp phức tạp hay phí tải dữ liệu hoặc API, với mức chi phí thấp và dễ định lượng giúp tiết kiệm, với bảo mật đứng đầu ngành và hiệu suất mà các doanh nghiệp có thể tin tưởng. Được khách hàng trên toàn thế giới tin tưởng, Wasabi được công nhận là một trong những công ty tăng trưởng nhanh nhất và có tầm nhìn xa nhất trong ngành công nghệ. Được sáng lập bởi David Friend và Jeff Flowers, những người tiên phong trong lưu trữ đám mây của Carbonite, Wasabi là một công ty tư nhân có trụ sở tại Boston. Wasabi là Đối tác Tự hào của Boston Red Sox, và là Đối tác Lưu trữ Đám mây Chính thức của Liverpool Football Club và Boston Bruins. <br /> <br />`;

const contentTest3 = `BOSTON, MA - Ngày 23 tháng 1 năm 2024 - Wasabi Technologies, công ty lưu trữ đám mây nổi tiếng, thông báo việc mua lại Curio AI từ GrayMeta, Inc. Chi tiết của thỏa thuận không được tiết lộ. Việc mua lại bao gồm cả tài sản trí tuệ và đội ngũ  đứng sau Curio, bao gồm CEO của GrayMeta, Aaron Edell, người sẽ tham gia Wasabi với vị trí Phó Chủ tịch Cấp cao về Trí tuệ Nhân tạo và Khoa học máy tính. Wasabi sẽ tích hợp công nghệ AI của Curio vào một loại lưu trữ thông minh mới dành cho ngành Công nghiệp Truyền thông và Giải trí mà công ty dự kiến sẽ phát hành vào Mùa Xuân năm 2024. Curio AI tạo ra chỉ mục từng giây của video được lưu trữ trong Wasabi. "Một kho lưu trữ video không có siêu dữ liệu chi tiết giống như một thư viện không có thẻ bài tập kỷ yếu," David Friend, Giám đốc điều hành của Wasabi Technologies, nói: "Đây là nơi mà trí tuệ nhân tạo phát huy tác dụng. AI có thể tìm thấy khuôn mặt, biểu tượng, đối tượng và thậm chí là các giọng nói cụ thể. Thiếu nó, việc tìm chính xác các đoạn bạn đang tìm kiếm đòi hỏi nhiều nỗ lực và tiêu tốn thời gian. Việc mua lại Curio AI sẽ cho phép chúng tôi cách mạng hóa lưu trữ phương tiện." <br /> <br />
Video là một trong những cơ hội lớn nhất trong ngành lưu trữ. Có hàng trăm exabyte của các kho lưu trữ video tồn tại trên các hệ thống băng cũ, và nhiều exabyte hơn được tạo ra hàng năm từ phim ảnh, truyền hình, sự kiện thể thao, tin tức, quảng cáo, giao tiếp doanh nghiệp và hàng giờ không đếm xuể của cảnh quay thô và những phần bị loại bỏ có giá trị. Thiếu siêu dữ liệu có thể tìm kiếm, việc tìm các đoạn video bạn cần vào bất kỳ thời điểm nào cụ thể là cực kỳ tốn thời gian và không hiệu quả. Curio AI là một nền tảng dữ liệu thông minh sử dụng trí tuệ nhân tạo để tạo ra siêu dữ liệu phong phú cho các thư viện truyền thông và cho phép các biên tập viên và nhà sản xuất tìm kiếm và lấy các đoạn phương tiện cụ thể ngay lập tức dựa trên người, địa điểm, sự kiện, cảm xúc, biểu tượng, điểm địa danh, âm thanh nền và nhiều hơn nữa. Curio AI cũng có thể phát hiện và chuyển chữ trong hơn 50 ngôn ngữ nói. Khách hàng được hưởng lợi từ các trải nghiệm cá nhân hóa với chi tiết cực kỳ cụ thể, cho phép tổ chức cung cấp nội dung phù hợp với thị trường càng nhanh càng tốt. Lưu trữ được trang bị trí tuệ nhân tạo của Wasabi's Curio AI sẽ cung cấp cho các khách hàng như Liverpool Football Club siêu dữ liệu mà họ cần để quản lý hàng loạt tài sản kỹ thuật số với tốc độ cực nhanh.<br /> <br />
"Việc lưu trữ được trang bị trí tuệ nhân tạo sẽ cho phép khách hàng của Wasabi tìm thấy ngay lập tức những gì họ cần trong hàng triệu giờ quay phim và phát huy giá trị từ các kho lưu trữ của họ. Chúng tôi tin rằng đây sẽ là bước tiến quan trọng nhất trong ngành lưu trữ kể từ khi phát minh ra lưu trữ đối tượng," Edell, Phó Chủ tịch Cấp cao mới của Wasabi về Trí tuệ Nhân tạo và Học máy, nói. "Tôi mong đợi được làm việc cùng Dave và đội ngũ xuất sắc mà ông đã xây dựng để viết ra một tương lai mới cho ngành công nghiệp truyền thông và giải trí."<br /> <br />
"Wasabi đang đi trên một quỹ đạo mà ít ai trong ngành có thể khẳng định. Ưu điểm của việc là một công ty tập trung cực kỳ là chúng ta có thể dẫn đầu ngành về giá cả, hiệu suất, độ tin cậy và sáng tạo. Với việc mua lại Curio AI, chúng tôi hiện đã sẵn sàng giới thiệu lưu trữ đám mây thông minh được trang bị trí tuệ nhân tạo đầu tiên trong ngành," Friend thêm. "Giống như lưu trữ đám mây tiêu chuẩn của Wasabi, lưu trữ được trang bị Curio AI của chúng tôi sẽ đơn giản, nhanh chóng, đáng tin cậy và giá rẻ. Với việc mua lại Curio AI, khách hàng của chúng tôi sẽ không chỉ có khả năng lưu trữ dữ liệu mà còn có thể định vị và lấy lại ngay lập tức chính xác những gì họ muốn."<br /> <br />`;

const contentTest4 = `
BOSTON và TOKYO — Ngày 1 tháng 3 năm 2023 — Các tổ chức ở châu Á-Thái Bình Dương, việc dùng điện toán đám mây để lưu trữ dữ liệu tăng nhanh, đặc biệt là cho các dự án sao lưu, lưu trữ lâu dài và khôi phục, nhưng lại bị ảnh hưởng nặng nề nhất bởi các khoản phí lưu trữ đám mây so với các đối tác toàn cầu của họ, theo Phần 2 của Chỉ số Lưu trữ Đám mây Toàn cầu năm 2023 của Wasabi. Wasabi Technologies đề xuất và thực hiện bởi Vanson Bourne, phát hiện ra các hiện trạng toàn cầu đối với việc áp dụng lưu trữ đám mây công cộng, các yếu tố ảnh hưởng đến quyết định mua lưu trữ, và các ưu tiên hàng đầu khi nói đến ngân sách, các trường hợp sử dụng, bảo mật và di dời dữ liệu đám mây. Phần 2 của Chỉ số Lưu trữ Đám mây Toàn cầu năm 2023 của Wasabi trình bày kết quả từ 200 người quyết định công nghệ thông tin tại Úc, Nhật Bản và Singapore.<br /> <br />
“Thị trường châu Á-Thái Bình Dương đặc biệt trong việc  áp dụng công nghệ mới và triển khai dịch vụ đám mây để hỗ trợ các dự án chuyển đổi số,”  theo tuyên bố Andrew Smith, quản lý cấp cao về chiến lược và thông tin thị trường tại Wasabi Technologies, cũng là một nhà phân tích trước đây tại IDC. “Mặc dù các khoản phí lưu trữ từ các hoạt động API, egress, và truy xuất dữ liệu chiếm hơn một nửa tổng chi phí lưu trữ đám mây của các tổ chức châu Á-Thái Bình Dương, khu vực này vẫn cho thấy một trong những tỷ lệ tăng trưởng dự đoán cao nhất cho lượng và ngân sách lưu trữ đám mây trong năm tới.”<br /> <br />
Các tổ chức APAC tận dụng lưu trữ đám mây và áp dụng nhiều nhà cung cấp: 85% các công ty APAC dự kiến lượng dữ liệu họ lưu trữ trên đám mây công cộng sẽ tăng trong năm tới, so với tỷ lệ trung bình toàn cầu là 84%. 87% các nhà quyết định công nghệ thông tin (IT) tại APAC dự kiến ngân sách lưu trữ đám mây của họ sẽ tăng trong năm 2023, so với tỷ lệ 84% trên toàn cầu. 93% đã di dời lưu trữ từ on-premises sang đám mây trong năm 2022, cao hơn so với tỷ lệ trung bình toàn cầu là 89%. 61% các tổ chức APAC sử dụng hơn một nhà cung cấp lưu trữ đám mây công cộng, cao hơn so với tỷ lệ trung bình toàn cầu là 57%.<br /> <br />
Các doanh nghiệp Úc dẫn đầu trong dự báo khu vực về ngân sách và mở rộng dung lượng lưu trữ đám mây: 92% các phản hồi từ Úc dự kiến lượng dữ liệu họ lưu trữ trên đám mây công cộng sẽ tăng trong năm 2023, so với Nhật Bản (84%) và Singapore (78%). 98% các nhà quyết định công nghệ thông tin (IT) tại Úc dự kiến ngân sách lưu trữ đám mây của họ sẽ tăng trong năm 2023, cao hơn nhiều so với tỷ lệ trung bình của APAC là 87%. Các công ty Úc đã phân bổ trung bình 14.4% của tổng ngân sách CNTT của tổ chức của họ cho dịch vụ lưu trữ đám mây công cộng trong khi các phản hồi từ Nhật Bản và Singapore lần lượt có ngân sách trung bình là 13.3% và 12.2%. 60% các phản hồi từ Úc đã vượt quá kỳ vọng về ngân sách cho lưu trữ đám mây trong năm ngoái, tỷ lệ cao nhất trong các quốc gia APAC.<br /> <br />
Nhật Bản cho thấy sự phổ biến cao của việc di dời lưu trữ đám mây để mở rộng vượt ra khỏi giới hạn của trang thiết bị trong nhà; nhưng gặp khó khăn với các khoản phí và ngân sách. Nhật Bản chỉ ra tỷ lệ phần trăm cao nhất của các khoản phí được phân bổ cho hóa đơn lưu trữ đám mây, lên đến 51%. Lý do hàng đầu dẫn đến chi phí quá mức ở Nhật Bản là việc sử dụng thực tế dữ liệu và lưu trữ cao hơn so với dự báo, kết hợp với các khoản phí vận hành dữ liệu cao hơn (ví dụ: sao chép giữa các khu vực) so với dự kiến. Yếu tố hàng đầu đẩy người phản hồi Nhật Bản di dời khả năng lưu trữ vào đám mây công cộng là nhu cầu mở rộng tài nguyên vượt ra ngoài khả năng của họ trên trang thiết bị trong nhà (43%). Tuy nhiên, khi đến vấn đề thách thức của việc di dời, Nhật Bản cho biết thời gian chết kế hoạch/không kế hoạch liên quan đến quá trình di dời là vấn đề hàng đầu của họ (40%).<br /> <br />
Ở Singapore, đứng đầu trên bảng xếp hạng những yếu tố quan trọng nhất khi lựa chọn nhà cung cấp lưu trữ đám mây. Người phản hồi xếp hạng sự bền vững ở vị trí hàng đầu trên danh sách (52%), so với tỷ lệ trung bình của APAC là 43%, và tỷ lệ trung bình toàn cầu là 43%. 96% tổ chức ở Singapore đã di dời lưu trữ từ on-premises sang lưu trữ đám mây trong năm ngoái, cao hơn so với tỷ lệ trung bình toàn cầu là 89%. Chỉ có 28% tổ chức ở Singapore vượt quá ngân sách lưu trữ đám mây trong năm ngoái, so với tỷ lệ trung bình của APAC là 51% và tỷ lệ trung bình toàn cầu là 52%. Yêu cầu mới về quy định và tuân thủ là một yếu tố quan trọng đẩy mạnh sự tăng trưởng ngân sách lưu trữ đám mây tại Singapore vào năm 2023 (49%), so với Nhật Bản và Úc (cả hai đều ở mức 35%).<br /> <br />
“Phân khúc thị trường lưu trữ đám mây APAC thường là phần phát triển nhanh nhất của thị trường, và nó có một số sự đa dạng lớn khi nói đến sự chín muồi và việc chọn nhà cung cấp. Các thông tin cụ thể về phân khúc thị trường này trong khu vực rất quan trọng đối với sự hiểu biết của chúng ta về hướng đi của thị trường và yêu cầu của người dùng cuối,” Smith nói. “Cũng đáng chú ý là vai trò quan trọng của sự bền vững trong quá trình lựa chọn nhà cung cấp đối với các tổ chức APAC, đặc biệt là tại Singapore, nơi sự bền vững được xếp hạng là yếu tố quan trọng nhất trong quá trình lựa chọn nhà cung cấp.” <br /> <br />`;

const contentTest6 = `<b>1. Tên chương trình khuyến mại: </b>Rồng Rắn Lên Mây<br /> <b>2. Địa bàn (phạm vi) khuyến mại:</b> Thành phố Hồ Chí Minh, Thành phố Hà Nội. <br />
<b>3. Hình thức khuyến mại:</b> Tặng hàng hóa, cung ứng dịch vụ không thu tiền có kèm theo việc mua bán hàng hóa, cung ứng dịch vụ.<br />
<b>4. Thời gian khuyến mại:</b> 23/02/2025 – 30/04/2025<br />
<b>5. Hàng hóa, dịch vụ khuyến mại:</b> Các sản phẩm thuộc thương hiệu Wasabi Hot Cloud Storage được QD.TEK phân phối chính hãng, sản phẩm khuyến mãi chọn lọc nằm trong phụ lục đính kèm.<br />
<b>6. Hàng hóa, dịch vụ dùng để khuyến mại:</b> Dung lượng lưu trữ Wasabi S3, Voucher Got It.<br />
<b>7. Khách hàng của chương trình khuyến mại (đối tượng được hưởng khuyến mại): </b>Tất cả khách hàng mua hàng tại QD.TEK.<br />
<b>8. Nội dung chi tiết của chương trình khuyến mại:</b><br />
<b>8.1. QUÀ TẶNG 1:</b><br />
Từ ngày 23/02/2025 – 30/04/2025, các khách hàng khi mua hàng mua sản phẩm thương hiệu Wasabi tại QD.TEK, ứng với số lượng TB dung lượng của mỗi đơn hàng, khách hàng sẽ tặng thêm 30% số lượng TB dung lượng với thời hạn tương ứng. Ví dụ: Đơn hàng mua 100TB dung lượng trong vòng 12 tháng sẽ được tặng thêm 30TB dung lượng trong vòng 12 tháng.<br /><br />
<table border="1" style="border-collapse: collapse; width: 70%; margin: 0 auto">
  <thead>
    <tr>
      <th style="padding: 8px; border: 1px solid black;">Thể lệ</th>
      <th style="padding: 8px; border: 1px solid black;">Điều kiện</th>

    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="padding: 8px; border: 1px solid black; width: 50%">  Ứng với số lượng TB dung lượng của mỗi đơn hàng, khách hàng sẽ tặng thêm 30% số lượng TB dung lượng với thời hạn tương ứng.</td>
      <td style="padding: 8px; border: 1px solid black;">Sản phẩm áp dụng: WasabiS3<br/>
      Thời hạn dịch vụ: từ 12 tháng trở lên</td>
    </tr>
   
  </tbody>
</table>

<br />

<b> ĐIỀU KIỆN & ĐIỀU KHOẢN ÁP DỤNG QUÀ TẶNG 1:</b><br />
- Chương trình áp dụng cho nhóm sản phẩm chọn lọc Wasabi S3, không áp dụng tất cả sản phẩm, khách hàng liên hệ nhân viên tư vấn để biết thêm chi tiết.<br />
- Tổng số lượng TB dung lượng đáp ứng đúng & đủ các mức thưởng.<br />
- Không giới hạn số lượng quà tặng cho mỗi khách hàng.<br />
- Các Khách hàng nào đạt đủ điều kiện nếu đăng ký phần quà trước sẽ được ưu tiên trước.<br />
- Khách hàng phải hoàn tất thanh toán đơn hàng trước ngày 30/04/2025, đơn hàng đã mua trong chương trình này sẽ không được đổi trả.<br />
- Quà tặng sẽ được áp dụng cùng thời điểm kích hoạt dịch vụ trong Đơn đặt hàng.<br />
- Đối với khách hàng nhận giải là cá nhân, khách hàng vui lòng cung cấp đầy đủ CMND/ CCCD để nhận giải.<br />
<b>8.2. QUÀ TẶNG 2:</b><br />
Từ ngày 23/02/2025 – 30/04/2025, các khách hàng khi mua hàng mua sản phẩm thương hiệu Wasabi tại QD.TEK, ứng với số lượng TB dung lượng của mỗi đơn hàng, khách hàng sẽ tặng thêm 30% số lượng TB dung lượng với thời hạn tương ứng. Ví dụ: Đơn hàng mua 100TB dung lượng trong vòng 12 tháng sẽ được tặng thêm 30TB dung lượng trong vòng 12 tháng.<br /><br />
<table border="1" style="border-collapse: collapse; width: 70%; margin: 0 auto">
  <thead>
    <tr>
      <th style="padding: 8px; border: 1px solid black;">Thể lệ</th>
      <th style="padding: 8px; border: 1px solid black;">Điều kiện</th>

    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="padding: 8px; border: 1px solid black; width: 50%"><p>&#x2022; Ứng với mỗi đơn hàng có số lượng dung lượng từ 25TB – 50TB, khách hàng sẽ được voucher Got It 500.000 VNĐ<br/> </p>  <p>&#x2022;  Ứng với mỗi đơn hàng có số lượng dung lượng từ 50TB – 100TB, khách hàng sẽ được voucher Got It 1.000.000 VNĐ</p>
      <p>&#x2022; Ứng với mỗi đơn hàng có số lượng dung lượng từ 100TB trở lên, khách hàng sẽ được voucher Got It 1.500.000 VNĐ</p>
      <p>&#x2022; Ứng với mỗi đơn hàng có số lượng dung lượng từ 200TB trở lên, khách hàng sẽ được voucher Got It 2.000.000 VNĐ</p>
    </td>
      <td style="padding: 8px; border: 1px solid black; vertical-align: top ">  Sản phẩm dạng RCS áp dụng: <br/>
      <p>&#x2022; WASABI OBJECT CLOUD STORAGE (S3)</p>
      <p>&#x2022; WASABI CLOUD NAS (CNAS)</p>
      <p>&#x2022; WASABI SURVEILLANCE CLOUD (WSC)</p>
     <br /> </td>
    </tr>
   
  </tbody>
</table><br />
<b> ĐIỀU KIỆN & ĐIỀU KHOẢN ÁP DỤNG QUÀ TẶNG 2:</b><br />
- Chương trình áp dụng cho tất cả sản phẩm thuộc nhãn hàng Wasabi, khách hàng liên hệ nhân viên tư vấn để biết thêm chi tiết.<br />
- Tổng số lượng TB dung lượng đáp ứng đúng & đủ các mức thưởng.<br />
- Không giới hạn số lượng quà tặng cho mỗi khách hàng.<br />
- Các Khách hàng nào đạt đủ điều kiện nếu đăng ký phần quà trước sẽ được ưu tiên trước.<br />
- Khách hàng phải hoàn tất thanh toán đơn hàng trước ngày 30/04/2025, đơn hàng đã mua trong chương trình này sẽ không được đổi trả.<br />
- Danh sách khách hàng thắng giải sẽ được QD.TEK công bố trong vòng 60 ngày sau khi kết thúc chương trình thông qua email mua hàng & khách hàng sẽ nhận được quà tặng trong vòng 30 ngày kể từ ngày công bố thông báo.<br />
- Đối với khách hàng nhận giải là cá nhân, khách hàng vui lòng cung cấp đầy đủ CMND/ CCCD để nhận giải. Trong trường hợp giải thưởng mà khách hàng nhận được trên 10 triệu đồng thì QD.TEK sẽ tiến hành thu bổ sung 10% phần tiền chênh lệch với 10 triệu đồng thông qua chuyển khoản. Ví dụ khách hàng nhận được giải thưởng trị giá 16.000.000 VNĐ thì khách hàng nộp bổ sung 600.000 VNĐ tiền thuế TNCN.<br /><br /> Mời trải nghiệm 1TB cho suốt 30 ngày hoàn toàn miễn phí tại: <a href="https://wasabi.qdtek.vn/trial-register"><b>https://wasabi.qdtek.vn/trial-register</b></a>
Thông tin chi tiết về chương trình khuyến mãi, khách hàng vui lòng liên hệ nhân viên kinh doanh của QD.TEK <a href="https://qdtek.vn/lien-he-chung-toi"> <b>(TẠI ĐÂY) </b></a>  hoặc thông qua email <b> info@qdtek.vn</b>. QD.TEK tự hào là nhà phân phối hàng đầu của Wasabi Technologies tại thị trường Việt Nam.
`;

const description = {
  id: 1,
  des: `Nâng cấp lên cloud chưa bao giờ dễ dàng đến thế! Sao lưu nhẹ nhàng như rồng lượn trên không, lại còn nhận ngay các ưu đãi hấp dẫn! QD.TEK & Wasabi ưu đãi TẶNG 30% DUNG LƯỢNG + VOUCHER GOT IT LÊN ĐẾN 2 TRIỆU ĐỒNG cho toàn thể quý Khách hàng và Đối tác. Liên hệ để được tư vấn ngay!`,
};

export const newsDetail = [
  {
    id: 1,
    day: "23/02/2025 – 30/04/2025",
    title: `RỒNG RẮN LÊN "MÂY" - TẶNG NGAY DUNG LƯỢNG`,
    description: description,
    content: contentTest6,
    image: "newn.png",
    slug: "rong-ran-len-may-tang-ngay-dung-luong",
  },
  {
    id: 2,
    day: "2 tháng 2, 2024",
    title: "LÊN “CLOUD” GIÁP THÌN - TẶNG THÊM 10% DUNG LƯỢNG",
    content: contentTest1,
    image: "banner-1.jpeg",
    slug: "len-cloud-giap-thin-tang-them-10-dung-luong",
  },
  {
    id: 3,
    day: "26 tháng 1, 2024",
    title:
      'QD.TEK nhận giải thưởng là "Đối tác Mới Xuất sắc Năm 2023 - khu vực Châu Á Thái Bình Dương" trong Lễ trao giải Wasabi Partner Network',
    content: contentTest2,
    image: "banner-2.jpeg",
    slug: "qdtek-nhan-giai-thuong-la-doi-tac-moi-xuat-sac-nam-2023",
  },
  {
    id: 4,
    day: "23 tháng 1, 2024",
    title: `Wasabi đã mua lại Curio AI từ GrayMeta. <br />Dự kiến ra mắt giải pháp lưu trữ trí tuệ nhân tạo cho M&E vào mùa xuân 2024`,
    content: contentTest3,
    image: "banner-3.png",
    slug: "wasabi-da-mua-lai-curio-ai-tu-graymeta",
  },
  {
    id: 5,
    day: "19 tháng 1, 2024",
    title: "Toshiba giảm 30% chi phí bằng cách sử dụng Lưu trữ Đám mây Wasabi.",
    content: contentTest4,
    image: "banner-4.png",
    slug: "toshiba-giam-30-chi-phi-bang-cach-su-dung-luu-tru-dam-may-wasabi",
  },
];
